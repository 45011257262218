import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as styles from './terms.module.scss';
import showdown from 'showdown';

const Terms = ({ condition }) => {
  const converter = new showdown.Converter();

  return (
    <Container>
      <Row className={styles.section}>
        <Col>
          <h3 className="title">{condition.title}</h3>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(condition.terms)
              }}
            />
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
